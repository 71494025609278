$max-width-mobile: 759px;
$min-width-tablet: 760px;
$max-width-tablet: 1279px;
$min-width-web: 1280px;

//colores
$blue: #2b265a;
$blue-modal: #160145;
$blue-report: #342761;
$blue-protecta: #2b0d61;
$grey-report: #eae9ee;
$line-height: 26px;
$orange: #de6d19;
$orange-report: #ff5100;
$orange-protecta: #ed6e00;
$white: #fff;
$gray: #8b8787;
$dark: #424242;

//print
$max-width-print-pdf: 560px !important;

//spacing
$spacing-base: 12.5px;

//font
//$nunito-bold: 'Nunito-Bold', 'sans-serif';
//$nunito-regular: 'Nunito', 'sans-serif';
