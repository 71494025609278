@import "../../../../assets/styles/variables";
.loading-payment{
  background: white!important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .footer{
    background: white !important;
    margin-top: 10px;
    padding-top: 25px;
    p{
      color: $blue-report;

    }
  }
  .loading{
    margin-top: 25px;
    .loader-progress {
      width:320px;
      margin:0 auto;
      border-radius:27px;
      border:2px solid $orange;
      position:relative;
      padding:10px;

      &:before {
        content:'';
        border:1px solid #fff;
        border-radius:10px;
        position:absolute;
        top:-4px;
        right:-4px;
        bottom:-4px;
        left:-4px;
      }

      .loaderBar {
        position:absolute;
        border-radius:10px;
        top:0;
        right:100%;
        bottom:0;
        left:0;
        background:#2B265A;
        width:0;
        animation:progressBar 2s linear infinite;
      }
    }

    @keyframes progressBar {
      0% {
        left:0;
        right:100%;
        width:0;
      }
      10% {
        left:0;
        right:75%;
        width:25%;
      }
      90% {
        right:0;
        left:75%;
        width:25%;
      }
      100% {
        left:100%;
        right:0;
        width:0;
      }
    }
  }
  .error-page{
    .modal-success-icon{
      path{
        fill : $blue-report;
      }

    }
    h1{
      font-size: 2rem;
      fill : $blue-report;
    }
  }


}
