.f-extraBold {
  font-family: 'BlissPro-ExtraBold', sans-serif;
}
.f-bold {
  font-family: 'BlissPro-ExtraBold', sans-serif;
}

.btn-submit {
  font-family: 'BlissPro-Bold', sans-serif !important;
}

.text-white {
  color: #ffff !important;
}

.size-small {
  font-size: 10px !important;
}

.size-medium {
  font-size: 25px !important;
}

.h-100 {
  height: 100%;
}
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}

.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}
.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.align-left {
  text-align: left !important;
}

.align-justify {
  text-align: justify !important;
}

.align-right {
  text-align: right !important;
}

.align-center {
  text-align: center !important;
}

form {
  padding: 0 20px;
}

.m-medium {
  margin: 0.5rem !important;
}

.m-1 {
  margin: 1rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.text-info {
  color: #ed6e00 !important;
}

.d-flex {
  display: flex;
}
.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-block {
  display: block;
}
.d-none {
  display: none;
}
