@font-face {
  font-family: 'BlissPro-Regular';
  src: url('./fonts/BlissPro-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'BlissPro-Bold';
  src: url('./fonts/BlissPro-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'BlissPro-Light';
  src: url('./fonts/BlissPro-Light.otf') format('truetype');
}

@font-face {
  font-family: 'BlissPro-ExtraBold';
  src: url('./fonts/BlissPro-ExtraBold.otf') format('truetype');
}

@font-face {
  font-family: 'BlissPro-ExtraLight';
  src: url('./fonts/BlissPro-ExtraLight.otf') format('truetype');
}

@font-face {
  font-family: 'BlissPro-Heavy';
  src: url('./fonts/BlissPro-Heavy.otf') format('truetype');
}
