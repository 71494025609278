@import './variables';
@import './breakpoints';
@import './typography';
@import './report';
@import './utils';

$breakpoints: (
  s: 0,
  m: 576px,
  l: 768px,
  lg: 992px,
  xl: 1200px,
);

html,
body {
  position: relative !important;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
  max-width: 100%;
  font-family: 'BlissPro-Regular', sans-serif;
}

input {
  font-family: 'BlissPro-Regular', sans-serif;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

select {
  font-family: 'BlissPro-Regular', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

button:hover {
  cursor: pointer;
}

.btn-secondary {
  background-color: #ffff !important;
  color: #ed6e00 !important;
  outline: none;
  font-family: 'BlissPro-Bold', sans-serif !important;

  &:hover {
    cursor: initial;
  }
}

.button-disabled {
  filter: opacity(0.6);
  background-color: #ed6e00;
  pointer-events: none;
  font-family: 'BlissPro-Bold', sans-serif !important;
  &:hover {
    cursor: initial;
  }
}

.button-link {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: #ed6e00 !important;
  font-size: 15px !important;
  text-decoration: underline !important;
  cursor: pointer;
  padding: 0;
  font-family: 'BlissPro-Bold', sans-serif !important;
}

.button-line {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: #e16d19 !important;
  font-size: 15px !important;
  cursor: pointer;
  padding: 0;
  font-family: 'BlissPro-Bold', sans-serif !important;
}

.item-disabled {
  filter: opacity(0.6);
  font-family: 'BlissPro-Bold', sans-serif !important;
  &:hover {
    cursor: initial;
  }
}

.exponent {
  font-size: 2rem;
  position: absolute;
}

.container-response {
  background: linear-gradient(207deg, #ed6e00 0%, #2b0d61 60%) !important;
}

.columns {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.columns:last-child {
  margin-bottom: 0 !important;
}

.react-responsive-modal-modal {
  padding: 0 !important;
  margin: auto auto !important;
}

.react-responsive-modal-closeButton {
  display: none !important;
}

.footer-content-align-left {
  label {
    text-align: left !important;
  }
}

.box-container-home {
  margin: 1rem 1rem 1rem 0;
  padding: 19px 0 5px 10px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  justify-items: center;
}
.button-small {
  width: 125px !important;
  height: 40px !important;
}

.button-medium {
  width: 155px !important;
  height: 40px !important;
}

.button-big {
  width: 200px !important;
  height: 50px !important;
}

.calendar-personal {
  .react-calendar__tile--active {
    background: #ed6e00;
    color: white;
    max-width: 8% !important;
    margin-left: 2.5%;
    padding-top: 1% !important;
    margin-top: 1% !important;
    height: 31px;
    border-radius: 50%;
  }
}

.goBack {
  &:hover {
    cursor: pointer;
  }
}

.titleHome {
  letter-spacing: 0;
  color: #1b1944;
  opacity: 1;
  font-size: 20px;
  font-weight: 700;

  &::after {
    content: '';
    border-bottom: #1b1944 0.2rem solid;
    position: relative;
    display: block;
    width: 0.5rem;
    left: 2px;
  }
}

.loading-pages-intranet {
  display: flex;
  justify-content: center;
  margin: 3rem;
}

.with-error {
  color: #ed6e00 !important;
  border: solid 1px #ed6e00 !important;
}

.container-title-section {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
}

.titleQuestion {
  font-family: 'BlissPro-ExtraBold', sans-serif !important;
  text-align: left;
  letter-spacing: 0;
  color: #ed6e00 !important;
  opacity: 1;
  font-size: 20px !important;
  font-weight: 700 !important;
}

.titleQuestion::after {
  content: '';
  border-bottom: #ed6e00 0.2rem solid;
  position: relative;
  display: block;
  width: 0.5rem;
  left: 2px;
}

.bg-gray {
  background: #f0f0f1 !important;
}

.title-summary-data {
  font-family: 'BlissPro-Heavy', sans-serif;
  font-size: 18px;
  text-align: left;
  letter-spacing: 0;
  color: #de6d19;
  opacity: 1;
}

.info-summary-data {
  font-size: 18px;
  font-family: 'BlissPro-Regular', sans-serif;
  text-align: left;
  letter-spacing: 0;
  color: #2b265a;
  opacity: 1;
}

.text-capitalize {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.loading-container {
  width: 100%;
  text-align: center;
  padding: 2%;
}

.link-term {
  color: #ed6e00;
  text-decoration: underline;
}

.hidden-content-force {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.text-uppercase {
  input {
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1024px) {
  .navbar-dropdown {
    border-top: 1px solid #e4e4e4;
    a.navbar-item {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 768px) {
  .display-none-response {
    display: none !important;
  }
  .exponent {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .display-only-response {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .display-none-mobile {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .display-only-mobile {
    display: none !important;
  }
}
