.download-file {
  font-family: 'initial', sans-serif !important;
  margin: 1.5rem;
  padding: 1.5rem !important;
  width: 540px !important;
  height: 620px !important;

  .report-body {
    .report-body-title {
      label {
        font-size: 14px !important;
        word-spacing: 2.5px;
      }
      .title-contancia {
        padding-right: 8.5px !important;
      }
    }
    .report-body-content,
    .report-body-v2 {
      .report-description {
        font-size: 11px !important;

        .description-fullName {
          word-spacing: 3px;
        }
      }
      .report-filters {
        .filter-row {
          font-size: 11px !important;
          h6 {
            min-width: 300px !important;
          }
        }
      }
    }
    .report-footer {
      .footer-description {
        font-size: 11px !important;
      }
      .footer-date {
        font-size: 11px !important;
      }
    }
    .report-firm {
      & div:nth-child(1) {
        width: 150px !important;
      }
      .report-firm-asignee {
        text-align: center;
        h5 {
          font-size: 12px !important;
        }
      }
    }
  }
}
.download-file-asegurado {
  font-family: 'initial', sans-serif !important;
  margin: 1.5rem;
  padding: 1.5rem !important;
  width: 540px !important;
  height: 620px !important;

  h6,
  h5 {
    font-size: 10px !important;
  }

  table {
    table-layout: fixed;
    border-collapse: collapse;
  }
  .report-body {
    .filter-row {
      margin-bottom: 0 !important;
    }
    .report-body-title {
      h6 {
        font-size: 15px !important;
      }
    }
    .report-table {
      border: 0.5px solid #acadab !important;
      th,
      tr {
        font-size: 9px !important;
      }
    }
    .report-footer {
      .footer-description {
        font-size: 11px !important;
      }
      .footer-date {
        font-size: 11px !important;
      }
    }
    .report-firm {
      & div:nth-child(1) {
        width: 150px !important;
      }
      .report-firm-asignee {
        text-align: center;
        h5 {
          font-size: 10px !important;
        }
      }
    }
  }
}

.download-file-boleta {
  font-family: 'initial', sans-serif !important;
  margin: 0 1rem;
  width: $max-width-print-pdf;
  .report-filters {
    .filter-row {
      font-size: 11px !important;
      h6 {
        min-width: 300px !important;
      }
    }
  }
}
.download-file-constancia {
  font-family: 'initial', sans-serif !important;
  margin: 1.5rem;
  padding: 1.5rem !important;
  width: 540px !important;

  .report-body {
    .report-body-title {
      padding: 5% 0 4% 0;
      text-align: center;
      h6:first-child {
        font-size: 20px !important;
        margin-bottom: 8px;
      }
      h6:nth-child(2) {
        font-size: 10px !important;
        font-weight: normal;
      }
      h6:nth-child(3) {
        font-size: 14px;
        margin: 7% 0 8px;
      }

      h6 {
        font-size: 10px !important;
      }
    }
    .report-description {
      text-align: justify;
      line-height: 18px;
      margin-bottom: 10px;
      label {
        font-size: 8px !important;
      }
    }
    .report-table {
      .table-header {
        td {
          font-size: 10px !important;
        }
      }
      table {
        width: 100%;
      }
      .table-title {
        margin: 5% 0 2% 0;
      }

      table,
      td,
      tr,
      th {
        border: 1px solid black;
      }
      thead {
        font-size: 12px;
        font-weight: 700;
        th {
          padding: 1px 0 0 3px;
          font-weight: 700;
        }
      }
      tbody {
        font-size: 11px;
        td {
          padding: 1px 0 0 3px;
        }
      }
    }
    .report-footer {
      margin-top: 5%;
      text-align: justify;
    }
    .report-firm {
      margin: 2% 0 2% 0;
      display: flex;
      justify-content: space-between;
      img {
        width: 200px;
        height: 100px;
        border-bottom: 1px dotted black;
      }
      .report-firm-asignee {
        text-align: center;
        h5 {
          font-size: 10px;
          font-weight: 700;
        }
      }
    }
    .report-message h5 {
      line-height: 17px;
    }
  }
}
